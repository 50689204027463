import React from 'react';

import { css, Theme } from '@emotion/react';
import Box from '@mui/material/Box';

import ProfileItem from '@/components/atoms/company/ProfileItem';

const ProfileContentsBox = (theme: Theme) => css({
  width: '90%',
  margin: '3rem auto',
  [theme.breakpoints.up('md')]: {
    margin: '2rem auto',
  }
});

const ProfileContents: React.VFC = () => {
  return (
    <Box css={ProfileContentsBox}>
      <ProfileItem
        title="商号"
        contentsName01="株式会社  富士見工務店"
      />
      <ProfileItem
        title="代表者"
        contentsName01="芳賀真人"
      />
      <ProfileItem
        title="本社"
        contentsList01={[
          '〒354-0035  埼玉県富士見市ふじみ野西4-10-1',
          'TEL:049-269-5811（代表）',
          'FAX:049-269-5861',
        ]}
      />
      <ProfileItem
        title="ふじみ野営業所"
        contentsList01={[
          '〒356-0051  埼玉県ふじみ野市亀久保1695-3',
          'TEL:049-264-3433',
          'FAX:049-269-5874',
        ]}
      />
      <ProfileItem
        title="坂戸第2機材センター"
        contentsName01="〒350-0256  埼玉県坂戸市善能寺203－6"
      />
      <ProfileItem
        title="資本金"
        contentsName01="96,000,000円（9600万円）"
      />
      <ProfileItem
        title="事業案内"
        contentsList01={[
          '木造免震住宅・鉄筋コンクリート造・鉄骨造',
          '木造・公共施設耐震補強工事・リフォーム・不動産取引',
          '土木工事・河川維持管理工事・アスベスト除去工事',
        ]}
      />
      <ProfileItem
        title="建設業の許可"
        contentsName01="埼玉県知事許可（特定）第2240号"
        contentsList01={[
          '建築工事業・大工工事業・左官工事業・とび・土工工事業・石工事業',
          '屋根工事業・タイル・レンガ・ブロック工事業・鋼構造物工事業・鉄筋工事業',
          '板金工事業・ガラス工事業・塗装工事業・防水工事業・内装仕上工事業・熱絶縁工事業・建具工事業',
        ]}
        contentsName02="埼玉県知事許可（一般）第2240号"
        contentsList02={[
          '土木工事業・舗装工事業・水道施設工事業',
        ]}
      />
      <ProfileItem
        title="設計事務所登録"
        contentsName01="埼玉県知事（8）第2326号  株式会社富士見工務店  建築設計事務所"
      />
      <ProfileItem
        title="宅建業免許"
        contentsName01="埼玉県知事（10）第11310号"
      />
      <ProfileItem
        title="資格者"
        contentsList01={[
          '１級建築施工管理技士',
          '２級建築士',
          '２級建築施工管理技士',
          '２級土木施工管理技士',
          '監理技術者',
          '埼玉県被災宅地応急危険度判定士',
        ]}
        contentsList02={[
          '宅地建物取引主任者',
          '耐震診断士',
          '住宅免震技術者',
          '建設業経理事務士',
          '埼玉県被災建築物応急危険度判定士',
          '特殊建築物等調査資格者',
        ]}
      />
      <ProfileItem
        title="保証機構等の加盟"
        contentsList01={[
          '（社）埼玉県建築士事務所協会',
          '（社）全国宅地建物取引業保証協会',
          '（社）埼玉県宅地建物取引業協会',
          '（財）住宅保証協会機構  第10009333号',
          '日本住宅保証検査機構（JIO）第A0100062号',
        ]}
      />
      <ProfileItem
        title="主要取引先盟"
        contentsList01={[
          '法務省・川越少年刑務所・さいたま拘置支所',
          '埼玉県・埼玉県警察・富士見市・ふじみ野市・三芳町・入間東部地区消防組合',
          '富士見市社会福祉事業団・富士見市社会福祉協議会・キラリ財団',
          '公益財団法人  埼玉県公園緑地協会',
        ]}
      />
    </Box>
  );
};

export default ProfileContents;
