import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import { css, Theme } from '@emotion/react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import InfoTypeDisplay from '@/components/molecules/common/InfoTypeDisplay';
import CEOImageBlock from '@/components/molecules/company/CEOImageBlock';
import TopMessageText from '@/components/molecules/company/TopMessageText';

const CEOImage = css({
  height: '100%',
});

const TopMessage: React.VFC = () => {
  return (
    <section>
      <Grid container direction="row-reverse">
        <Grid item xs={12}
          sx={{
            display: {
              xs: 'block',
              sm: 'none',
              md: 'none',
              lg: 'none'
            }
          }}
        >
          <InfoTypeDisplay
            titleTextEn={['Top', 'Message']}
            titleText="代表挨拶"
            backgroundColor="primary"
          />
        </Grid>
        <Grid item xs={12} sm={8} lg={9}>
          <TopMessageText />
        </Grid>
        <Grid item xs={12} sm={4} lg={3}>
          <CEOImageBlock
            labelNameEn="Top Message"
            labelName="代表挨拶"
          >
            <StaticImage
              alt="ceo image"
              src="../../../images/others/ceo.jpg"
              css={CEOImage}
              objectPosition="50% 0%"
            />
          </CEOImageBlock>
        </Grid>
      </Grid>
    </section>
  );
};

export default TopMessage;

