import React from 'react';
import { PageProps } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import { css, Theme } from '@emotion/react';

import Layout from '../layouts/Layout';
import FirstView from '@/components/organisms/common/FirstView';
import TopMessage from '@/components/organisms/company/TopMessage';
import Profile from '@/components/organisms/company/Profile';
import Access from '@/components/organisms/company/Access';
import ContactLinkBlock from '@/components/organisms/common/ContactLinkBlock';
import BottomMenu from '@/components/organisms/common/BottomMenu';
import Seo from '@/components/organisms/common/Seo';

const FirstViewImage = (theme: Theme) => css({
  height: '25vh',
  [theme.breakpoints.down('xs')]: {
    height: '20vh',
  }
});

const CompanyPage: React.VFC<PageProps> = () => {
  return (
    <Layout>
      <Seo
        title="会社情報"
        description="株式会社 富士見工務店の会社情報についてまとめています。"
      />
      <FirstView
        titleEn="Company"
        title="会社情報"
        titlePosition="left"
      >
        <StaticImage
          alt="CompanyImage"
          src="../images/firstviews/company.jpg"
          css={FirstViewImage}
          layout="fullWidth"
          objectPosition="50% 60%"
        />
      </FirstView>
      <TopMessage />
      <Profile />
      <Access />
      <ContactLinkBlock />
      <BottomMenu />
    </Layout>
  );
};

export default CompanyPage;
