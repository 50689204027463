import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import { css, Theme } from '@emotion/react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import InfoTypeDisplay from '@/components/molecules/common/InfoTypeDisplay';
import ProfileContents from '@/components/molecules/company/ProfileContents';

const fillImageBox = css({
  height: 'calc(100% - 30vh)'
});

const fillImage = css({
  height: '100%'
});

const Profile: React.VFC = () => {
  return (
    <section>
      <Grid container>
        <Grid item xs={12} md={3}>
          <InfoTypeDisplay
            titleTextEn={['Profile']}
            titleText="会社概要"
            backgroundColor="secondary"
            fixed
          />
          <Box
            css={fillImageBox}
            sx={{
              display: {
                xs: 'none',
                sm: 'none',
                md: 'block',
                lg: 'block'
              }
            }}
          >
            <StaticImage
              alt="inCompanyProfile01"
              src="../../../images/decoration/inCompanyProfile01.jpg"
              objectFit="cover"
              css={fillImage}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={9}>
          <ProfileContents />
        </Grid>
      </Grid>
    </section>
  );
};

export default Profile;
