import React from 'react';

import { css, Theme } from '@emotion/react';
import Typography from '@mui/material/Typography';

type ProfileItemProps = {
  title: string;
  contentsName01?: string;
  contentsList01?: Array<string>;
  contentsName02?: string;
  contentsList02?: Array<string>;
};

const contentNameStyle = css({
  margin: '1rem auto'
});

const profileContentTitle = css({
  fontWeight: 600
});

const profileContentList = css({
  listStyle: 'none',
  paddingInlineStart: 0
});

const ProfileItem: React.VFC<ProfileItemProps> = (props) => {
  const {title, contentsName01, contentsList01, contentsName02, contentsList02} = props;
  return (
    <dl>
      <dt>
        <Typography css={profileContentTitle}>{ title }</Typography>
      </dt>
      <dd>
        {contentsName01 &&
          <Typography css={contentNameStyle}>{ contentsName01 }</Typography>
        }
        {contentsList01 &&
          <ul css={profileContentList}>
            {contentsList01.map(content => {
              return (
                <li key={content}>
                  <Typography>
                    { content }
                  </Typography>
                </li>
              );
            })}
          </ul>
        }
        {contentsName02 &&
          <Typography css={contentNameStyle}>{ contentsName02 }</Typography>
        }
        {contentsList02 &&
          <ul css={profileContentList}>
            {contentsList02.map(content => {
              return (
                <li key={content}>
                  <Typography>
                    { content }
                  </Typography>
                </li>
              );
            })}
          </ul>
        }
      </dd>
    </dl>
  );
};

export default ProfileItem;
