import React from 'react';

import { css, Theme } from '@emotion/react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const topMessageWrapper = css({
  height: '100%',
  display: 'flex',
  alignItems: 'center'
});

const topMessageTextBox = css({
  width: '90%',
  margin: '0 auto',
  padding: '2rem 0'
});

const paraStyles = css({
  margin: '1rem auto'
});

const brAdjust = css({
  display: 'inline-block',
});

const TopMessageText: React.VFC = () => {
  return (
    <Box
      css={topMessageWrapper}
      sx={(theme) => ({
        background: {
          xs: theme.palette.background.default,
          sm: theme.palette.primaryGradation.default,
          md: theme.palette.primaryGradation.default,
          lg: theme.palette.primaryGradation.default,
        }
      })}
    >
      <Box css={topMessageTextBox}>
        <Typography
          css={paraStyles}
          sx={{
            color: {
              xs: '#231815',
              sm: '#ffffff',
              md: '#ffffff',
              lg: '#ffffff',
            }
          }}
        >
          <span css={brAdjust}>ホームページにお越しくださいまして、</span>
          <span css={brAdjust}>ありがとうございます。</span>
          <br />
          <span css={brAdjust}>弊社は1959年創業の建設会社でございます。</span>
        </Typography>
        <Typography
          css={paraStyles}
          sx={{
            color: {
              xs: '#231815',
              sm: '#ffffff',
              md: '#ffffff',
              lg: '#ffffff',
            }
          }}
        >
          <span css={brAdjust}>創業者である父が福島県会津から</span>
          <span css={brAdjust}>現在の富士見市に移住する時、</span>
          <br />
          <span css={brAdjust}>「地元に根をおろして頑張りたい」</span>
          <br />
          <span css={brAdjust}>という想いから富士見市の</span>
          <span css={brAdjust}>富士見工務店と命名しました。</span>
        </Typography>
        <Typography
          css={paraStyles}
          sx={{
            color: {
              xs: '#231815',
              sm: '#ffffff',
              md: '#ffffff',
              lg: '#ffffff',
            }
          }}
        >
          <span css={brAdjust}>弊社スタッフは少数ではございますが、</span>
          <span css={brAdjust}>常にお客様の視点に立ち、</span>
          <br />
          <span css={brAdjust}>安心と満足のために活動して</span>
          <span css={brAdjust}>参る所存でございます。</span>
          <br />
          <span css={brAdjust}>私たちは、お客様の期待を超える</span>
          <span css={brAdjust}>価値を提供し続けるべく</span>
          <span css={brAdjust}>信頼に応えてまいります。</span>
        </Typography>
        <Typography
          css={paraStyles}
          sx={{
            color: {
              xs: '#231815',
              sm: '#ffffff',
              md: '#ffffff',
              lg: '#ffffff',
            }
          }}
        >
          <span css={brAdjust}>これからも時代の変化に</span>
          <span css={brAdjust}>スピーディに対応し、</span>
          <span css={brAdjust}>技術やサービスの向上に努めてまいります。</span>
          <br />
          <span css={brAdjust}>今後とも皆様のご指導、ご愛顧を</span>
          <span css={brAdjust}>賜りますようお願い申し上げます。</span>
        </Typography>
        <Typography
          css={paraStyles}
          sx={{
            color: {
              xs: '#231815',
              sm: '#ffffff',
              md: '#ffffff',
              lg: '#ffffff',
            }
          }}
        >
          代表取締役 芳賀 真人
        </Typography>
      </Box>
    </Box>
  );
};

export default TopMessageText;
