import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import { css, Theme } from '@emotion/react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import InfoTypeDisplay from '@/components/molecules/common/InfoTypeDisplay';

const accessContentBox = css({
  width: '100%',
  height: '100%',
  minHeight: '54vh',
});

const mapBox = css({
  width: '100%',
  height: 'calc(100% - 8rem)',
  margin: 0
});

const map = css({
  width: '100%',
  height: '100%',
  border: 0,
  margin: 0,
  padding: 0,
});

const imageStyles = css({
  height: '100%',
});

const accessMessageBox = (theme: Theme) => css({
  height: '8rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.backgroundDark.default,
  margin: 0
});

const accessMessage = css({
  width: '90%',
  color: '#ffffff',
  lineHeight: '1.8rem',
});

const Access: React.VFC = () => {
  return (
    <section>
      <Grid container direction="row-reverse">
        <Grid item xs={12} md={3}>
          <InfoTypeDisplay
            titleTextEn={['Access']}
            titleText="アクセス"
            backgroundColor="primary"
            fixed
          />
          <Box
            sx={{
              height: 'calc(100% - 30vh)',
              display: {
                xs: 'none',
                sm: 'none',
                md: 'block',
                lg: 'block'
              }
            }}
          >
            <StaticImage
              alt="inCompanyProfile02"
              src="../../../images/firstviews/company.jpg"
              objectFit="cover"
              css={imageStyles}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={9}>
          <Box css={accessContentBox}>
            <Box css={mapBox}>
              <iframe title="access map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3233.658326443117!2d139.51473141555044!3d35.857380727897905!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6018dcc63bcd3ed1%3A0x660936bfa8740a74!2z44CSMzU0LTAwMzUg5Z-8546J55yM5a-M5aOr6KaL5biC44G144GY44G_6YeO6KW_77yU5LiB55uu77yR77yQ4oiS77yR!5e0!3m2!1sja!2sjp!4v1639480657210!5m2!1sja!2sjp" allowFullScreen loading="lazy" css={map} />
            </Box>
            <Box css={accessMessageBox}>
              <Typography css={accessMessage}>
                本社所在地：
                <br />
                〒354-0035
                <br />
                埼玉県富士見市ふじみ野西4－10－1
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </section>
  );
};

export default Access;
