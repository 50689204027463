import React from 'react';

import { css, Theme } from '@emotion/react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

type ImageLabelProps = {
  labelName: string;
  labelNameEn: string;
}

const imageLabelBox = (theme: Theme) => css({
  backgroundColor: theme.palette.background.default,
  position: 'absolute',
  bottom: 0,
  left: 0,
  padding: '.75rem 2rem',
});

const enLabelStyle = css({
  fontSize: '1.3rem',
});

const ImageLabel: React.VFC<ImageLabelProps> = (props) => {
  const {labelName, labelNameEn} = props;

  return (
    <Box
      css={imageLabelBox}
      sx={{
        display: {
          xs: 'none',
          sm: 'block',
          md: 'block',
          lg: 'block'
        }
      }}
    >
      <Typography css={enLabelStyle}>
        { labelNameEn }
      </Typography>
      <Typography>
        { labelName }
      </Typography>
    </Box>
  );
};

export default ImageLabel;
