import React from 'react';

import { css } from '@emotion/react';
import Box from '@mui/material/Box';

import ImageLabel from '@/components/atoms/company/ImageLabel';

type CEOImageProps = {
  children: React.ReactNode;
  labelName: string;
  labelNameEn: string;
};

const CEOImageBox = css({
  position: 'relative',
  height: '100%',
  minHeight: '20vh'
});

const CEOImageBlock: React.VFC<CEOImageProps> = (props) => {
  const {children, labelName, labelNameEn} = props;

  return(
    <Box css={CEOImageBox}>
      {children}
      <ImageLabel
        labelName={labelName}
        labelNameEn={labelNameEn}
      />
    </Box>
  );
};

export default CEOImageBlock;
